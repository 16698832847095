@import 'palette';
@import 'text/mixins';

$image-size-px: 40px;
$image-pull-px: 3;
$image-multi-max: 3;

.task-separator {
  padding-left: 10px;
  color: black;
  height: 20px;

  font-size: 12px;
  font-weight: bold;
}

.task-simple {
  width: 100%;
  height: 72px;
  border: 1px solid $very-light-pink;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  transition-property: border-color;
  transition-duration: 0.25s;

  &-list-item {
    border: none;
    border-left: 2px solid transparent;
    border-bottom: 1px solid $very-light-pink-two;
    border-radius: 0;
    transition-property: background-color;
    transition-duration: 0.25s;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background-color: $white;
    }
  }

  &-active {
    border-left: 2px solid $tealish-two;
  }

  &-highlight {
    background-color: $light-blue;
    .task-simple-name,
    .task-simple-text {
      font-weight: bold;
      color: #333 !important;
    }
  }

  &-image {
    position: absolute;
    height: 40px;
    width: 40px;

    &-container {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      margin-left: 9px;
      min-width: $image-size-px;
    }

    @for $i from 0 through $image-multi-max {
      &-#{$i + 1} {
        right: #{$i * $image-pull-px}px;
        z-index: #{$image-multi-max - $i};
      }
    }
  }

  &-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  }

  &-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 7px;
    flex-grow: 1;
    margin-left: 10px;

    h4 {
      margin: 0;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: $brownish-grey;
  }

  &-name {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include ListHeadline;
  }

  &-text {
    @include ListText;

    margin-left: 0;
    text-indent: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-indicators {
    font-size: 10px;

    &-processing {
      color: $brownish-grey;
    }

    &-encoding-error {
      color: $rust;
    }

    &-delivered {
      color: $tealish-two;
    }

    &-blocked {
      color: $brownish-grey;
    }

    &-opened {
      color: $fern;
    }

    &-delivery-error {
      color: $rust;
    }

    &-watched {
      color: $tealish-three;
    }

    &-reacted {
      color: $darkish-pink;
    }

    &-replied {
      color: $robin-s-egg;
      margin-right: 6px !important;
    }

    &-clicked {
      color: $golden;
    }

    i,
    span {
      margin-right: 5px;
    }
  }

  &-right {
    display: flex;
    color: #9e9e9e;
    font-size: 12px;
    font-weight: normal;
    align-items: center;
    padding-right: 13px;
    min-width: 54px;
  }
}

.max-send-limit {
  img.banner {
    width: 100%;
  }
  .body {
    padding: 25px 40px 20px;
    text-align: center;

    h2 {
      max-width: 300px;
      margin: 0 auto 25px;
    }

    .text {
      margin: 0 auto;
      width: 100%;
      max-width: 325px;
      color: #7d7c7c;

      p {
        margin-bottom: 15px;
      }
    }

    .btn.btn-primary {
      width: 100%;
      margin-bottom: 20px;
      img {
        margin-right: 5px;
      }
    }

    .learn-more {
      padding: 20px 10px;
      color: #7d7c7c;
      background: #faf8f0;
      margin-bottom: 20px;
    }
  }
}

#typeform-form-container {
  .task-simple {
    border: none;
  }
}
