@import '~compass-mixins/lib/compass/css3/border-radius';
@import './colours';

.helper-popover {
  padding: unset;
  font-family: inherit;
  background-color: unset;
  border: unset;
  box-shadow: unset;
  .Popover-body {
    background-color: $blue;
    max-width: 250px;
    color: white;
    padding: 10px 15px;
    @include border-radius(10px);

    &-header {
      font-size: 1.5em;
      font-weight: bold;
      padding: 0 0 5px 0;
      margin: 0;
    }

    &-main {
      font-size: 1.1em;
      font-weight: bold;
    }
  }

  .Popover-tip {
    fill: $blue;
  }
}
