@import 'palette';

.btn-link-icon {
  @extend .btn-link;

  .far,
  .fas,
  .fal {
    color: #999999;

    &:hover,
    &:focus {
      color: $tealish-two;
    }
  }
}
