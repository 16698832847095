@import 'palette';
@import './buttons/mixins';

.btn {
  border: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  padding: 8px 16px;

  .far,
  .fab,
  .fas,
  .fal {
    font-weight: normal;
  }

  .svg-inline--fa {
    margin-right: 5px;
  }
  &.no-child {
    .svg-inline--fa {
      margin-right: unset;
    }
  }

  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }

  &.btn-default {
    @include ButtonStyle($brownish-grey, $very-light-pink);
    font-weight: normal;
  }

  &.btn-info {
    @include ButtonStyle(#7d7c7c, #efefef);

    &:focus:active {
      color: #7d7c7c;
    }
  }

  &.btn-important {
    @include ButtonStyle(#42332a, $sunflower-yellow);
  }

  &.btn-primary {
    @include ButtonStyle(white, $tealish-two);
    text-decoration: none;
  }

  &.btn-warning,
  &.btn-danger {
    @include ButtonStyle(white, $darkish-pink);
  }

  &.btn-success {
    @include ButtonStyle($white, $lightish-green);
  }

  &.btn-secondary {
    @include ButtonStyle($tealish-two, white, $very-light-pink);

    border: solid 1px $very-light-pink-three;

    padding: 7px 16px;
  }

  &.btn-dropdown {
    @include ButtonStyle(#717171, white, $very-light-pink);

    font-weight: normal;

    &.active {
      color: $tealish-two;
      background-color: white;
      outline: none;
      box-shadow: none;
    }

    i {
      font-size: 0.85em;
      padding-left: 5px;
    }
  }

  &.btn-dropdown-inverted {
    @include ButtonStyle(#717171, $very-light-pink, white);

    font-weight: normal;

    &.active {
      color: $tealish-two;
      background-color: white;
      outline: none;
      box-shadow: none;
    }

    i {
      font-size: 0.85em;
      padding-left: 5px;
    }
  }

  &.btn-apple {
    @include ButtonStyle(white, black);
  }

  &.btn-google {
    @include ButtonStyle(white, #397af2);
  }

  &.btn-lg {
    font-size: 14px;
    padding: 11px 16px;

    i {
      margin-right: 8px;
    }
  }

  &.btn-sm {
    padding: 5px 15px;
    font-weight: normal;
  }

  &[disabled] {
    color: $brown-grey;
    background-color: #e7e7e7;

    &:focus,
    &:hover {
      color: $brown-grey;
      background-color: #e7e7e7;
    }
  }
}

.btn-upgrade {
  border-radius: 4px;
  border: solid 1px $tealish-two;
  background-color: #daf6f7;
  width: 100%;
  margin: 8px 0px;
  height: 35px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.16px;
  color: $tealish-two;
  position: relative;

  i {
    color: #ffd002;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  :focus {
    outline: unset;
  }
}
