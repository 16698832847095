@import 'colours.scss';

a:hover.no-text-decoration {
  text-decoration: none;
}

.table-borderless {
  tbody > tr > th,
  tbody > tr > td,
  tfoot > tr > td,
  tfoot > tr > th,
  thead > tr > td,
  thead > tr > th {
    border: none !important;
    border-top: none !important;
  }
}

.horizontal-flex-list {
  font-family: Lato;
  display: flex;
  font-size: 1.15em;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: $grey;
    padding-right: 0.1em;

    &.active {
      color: $grey-dark;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
