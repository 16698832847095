@import 'palette';
@import 'text/mixins';

ul.list-group {
  .list-group-item {
    height: 71px;
    border: none;
    border-bottom: 0.5px solid $very-light-pink;
    background-color: transparent;
    padding: 0;

    &:not(.active):hover {
      background-color: $very-light-pink;
    }

    &.active,
    &.active:hover,
    &.active:focus {
      background-color: $white;
      border-color: $very-light-pink;

      .list-group-item-icon {
        color: $tealish-two;
      }
    }

    .list-group-item-heading {
      &,
      &:hover,
      &:focus {
        @include ListHeadline;

        margin: 7px 0 0;
      }
    }

    .list-group-item-text {
      &,
      &:hover,
      &:focus {
        @include ListText;
      }
    }

    .list-group-item-icon {
      display: flex;
      align-items: center;
      float: left;
      font-size: 20px;
      margin: 0 18px;
      color: $brown-grey;
      height: 100%;
      width: 20px;
    }

    a {
      display: block;
      height: 100%;
      width: 100%;
      padding: 10px 15px;
    }
  }
}
