@import './palette';
@import './colours';
@import './buttons/mixins';

.pagination {
  font-size: 1.3em;

  .active {
    a {
      @include ButtonStyle($white, $tealish-two);
      border-color: $tealish-two !important;
    }
  }

  li {
    &.active {
      span {
        color: $grey-dark;
        background-color: $yellow;
        border-color: $grey;

        &:focus,
        &:hover {
          background-color: $grey-dark;
          color: #eee;
          border-color: $brown-dark;
        }
      }
    }

    a {
      @include ButtonStyle($brownish-grey, $very-light-pink);
    }
  }

  .disabled {
    a,
    a:hover,
    a:focus,
    span {
      color: $brown-grey;
      background-color: #e7e7e7;

      &:focus,
      &:hover {
        color: $brown-grey;
        background-color: #e7e7e7;
      }
    }
  }
}
