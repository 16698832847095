@import 'breakpoint-sass';

.navbar-nav > li > .dropdown-menu {
  @include border-radius(4px);
  @include box-shadow(0 2px 12px rgba(0, 0, 0, 0.2));

  a {
    font-family: Lato;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.08px;
    color: $camo-green;
    padding: 10px 20px;
  }
}

.navbar-nav {
  .dropdown {
    &.open {
      .dropdown-toggle {
        background-color: unset !important;
      }
      a > i.fa-chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdown-toggle {
  margin-right: 0 !important;
}

$navigation-thickness: 58px;

.navigation {
  &-top {
    min-height: $navigation-thickness;
    padding-right: 20px;
    #nav-alert {
      margin: auto;
    }
    div:last-child {
      margin-left: auto;
    }
  }

  &-left {
    width: $navigation-thickness;

    display: flex;
    flex-direction: column;
  }
}

#workspace-navigation-header {
  margin: 0 auto 0 0;
}
@media (max-width: 768px) {
  .navigation {
    &-top {
      #workspace-navigation-header {
        width: unset;
      }
      #nav-pro-tip {
        display: none;
      }
    }
  }
}
