@import 'mixins/layout';

.panel-main,
.panel-content > .panel-body {
  @include MainPanel;

  @media (min-width: 1250px) {
    & > .form-control,
    & > .form-group > .form-control,
    & > form > .form-group > .form-control,
    & > form > .form-control {
      max-width: 60%;
    }
  }
}

.panel-main {
  @media (max-width: 768px) {
    padding: 20px;
  }
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.scroll-y {
  overflow-x: hidden;
  overflow-y: auto;
}
