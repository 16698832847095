@import 'palette';
@import 'text/mixins';

.body-copy {
  @include BodyCopy;
}

h1,
.heading {
  @include Heading;
}

h2,
.sub-heading {
  @include SubHeading;
}

h3,
.card-heading {
  @include CardHeading;
}

.card-body {
  @include BodyCopy;
  color: $brown-grey-two;
}

h4,
.workflow-guide-heading-active {
  @include WorkflowGuideHeadingActive;
}

h5,
.workflow-guide-heading-inactive {
  @include WorkflowGuideHeadingInactive;
}

.list-heading {
  @include ListHeadline;
}

.list-text {
  @include ListText;
}

.light-teal {
  color: #99e4d8;
}

.aqua-marine {
  color: #32d6bc;
}

.tealish {
  color: #2dc2ab;
}

.robin-s-egg {
  color: #6ae4e8;
}

.tealish-two {
  color: #27b3c1;
}

.tealish-three {
  color: #1f96a2;
}

.parchment {
  color: #fff1b2;
}

.sunflower-yellow {
  color: #ffd002;
}

.golden {
  color: #f0c300;
}

.light-peach {
  color: #f9d5c1;
}

.bright-orange {
  color: #fc5a00;
}

.rust {
  color: #963b09;
}

.light-pink {
  color: #ffeff4;
}

.rosy-pink {
  color: #ec799d;
}

.darkish-pink {
  color: #d0577d;
}

.lightish-green {
  color: #6add73;
}

.cool-green {
  color: #2dd23b;
}

.fern {
  color: #52ab5a;
}

.white {
  color: #f8f8f8;
}

.very-light-pink {
  color: #f2f2f2;
}

.very-light-pink-two {
  color: #e6e6e6;
}

.very-light-pink-three {
  color: #cdcdcd;
}

.brown-grey {
  color: #b4b4b4;
}

.brown-grey-two {
  color: #878787;
}

.brownish-grey {
  color: #666666;
}

.light-blue {
  color: #effdfe;
}

.text-truncate {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
