@import 'palette';
@import 'text/mixins';

.card {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white;

  @include BodyCopy;
  color: $brown-grey-two;

  & > .heading {
    @include CardHeading;
  }

  & > p:last-child {
    margin-bottom: 0;
  }

  &.active {
    border: 1px solid $tealish-two;
  }

  &-group {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 2px solid transparent;

    &.active {
      border: 2px solid $tealish-two;
    }

    &.disabled {
      opacity: 0.5;
      user-select: none;
      cursor: not-allowed;

      & > * {
        pointer-events: none;
      }
    }

    & > .card-group {
      box-shadow: none;
      border-radius: 0;

      & > .card:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    & > .card {
      border-radius: 0;
      box-shadow: none;
      border: none;
      border-bottom: 2px solid $very-light-pink;
    }

    & > .card:last-child {
      border-bottom: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    & > .card:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}
