$remove-color: #888 !default;
$remove-hover-color: #555 !default;
$remove-width: 20px !default;

$selection-color: #444 !default;

$border-color: #aaa !default;
$border-radius: 4px !default;

$focus-border-color: #5897fb !default;

$container-height: 28px !default;

$selection-bg-top-color: white !default;
$selection-bg-bottom-color: #eeeeee !default;

$container-placeholder-color: #999 !default;

$container-focus-border-color: blue !default;

$selection-opened-bg-top-color: $selection-bg-bottom-color !default;
$selection-opened-bg-bottom-color: $selection-bg-top-color !default;

$dropdown-z-index: 1 !default;

$dropdown-bg-color: $selection-bg-top-color !default;

$results-max-height: 200px !default;
$results-nested-padding: 20px !default;

$results-choice-bg-hover-color: #3875d7 !default;
$results-choice-fg-hover-color: white !default;

$results-choice-fg-unselectable-color: grey !default;

.select2-container {
  width: 100%;
  display: block;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  vertical-align: middle;

  @import 'single';
  @import 'multiple';
}

@import 'dropdown';

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;

  // styles required for IE to work

  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--bonjoro {
  @import 'single';
  @import 'multiple';

  .select2-search--dropdown {
    .select2-search__field {
      border: 1px solid $border-color;
      outline: 0;
    }
  }

  .select2-search--inline {
    .select2-search__field {
      outline: 0;
      box-shadow: none;
    }
  }

  .select2-dropdown {
    background-color: $dropdown-bg-color;
    border: 1px solid transparent;
  }

  .select2-dropdown--above {
    border-bottom: none;
  }

  .select2-dropdown--below {
    border-top: none;
  }

  .select2-results > .select2-results__options {
    max-height: $results-max-height;
    overflow-y: auto;
  }

  .select2-results__option {
    &[role='group'] {
      padding: 0;
    }

    &[aria-disabled='true'] {
      color: $results-choice-fg-unselectable-color;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $results-choice-bg-hover-color;
    color: $results-choice-fg-hover-color;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &.select2-container--open .select2-dropdown {
    border-color: $focus-border-color;
  }
}
