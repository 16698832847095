@import 'text';
@import 'palette';
@import 'colours';
@import '~compass-mixins/lib/compass/css3/user-interface';
@import '../partials/react-tel-input';

.form-control {
  font-size: 13px;
  font-weight: normal;
  color: $brownish-grey;
  border-radius: 5px;
  border: solid 1px $very-light-pink-three;
  outline: none;
  height: 40px;
  box-shadow: none;

  &::placeholder {
    color: lighter($brownish-grey, 20%);
  }

  &:focus {
    outline: none;
    border: 1px solid $tealish-two;
    box-shadow: none;
  }

  &.is-invalid {
    border: 1px solid #d0577d;
  }
}

input[type='text'].form-control,
input[type='email'].form-control,
textarea.form-control {
  &:focus {
    color: $tealish-two;
  }
}

.input-group {
  position: relative;

  &-addon {
    background-color: $very-light-pink;
    border: 1px solid $very-light-pink-three;
    z-index: 99;
    color: $brown-grey-two;
  }
}

.form-group {
  .form-header-group {
    .sub-heading {
      margin-bottom: 0;
    }
  }
}

.control-label,
form .form-group > label {
  @include BodyCopy;

  &.sub-heading {
    @include SubHeading;
  }
}

.help-block {
  @include BodyCopy;

  &-danger {
    color: $bright-orange;
  }
}

.simple-form-body {
  h2 {
    font-family: 'Circular Std', Arial, sans-serif;
    margin: 30px 0;
  }
}

.radio label,
.checkbox label {
  @include BodyCopy;
}

.checkbox label {
  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    top: -1px;
  }
}

.radio {
  &.position-right {
    label {
      width: 100%;
      padding-left: 0;
    }
    input {
      right: 0;
    }
  }
}
