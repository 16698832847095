@import 'palette';
@import 'text/mixins';

.table {
  font-size: 14px;

  thead {
    background-color: $white;
    color: $brownish-grey;

    i,
    .badge {
      margin-right: 7px;
    }

    i {
      color: $brown-grey;
    }

    .badge {
      font-size: 10px;
      color: white;
      background-color: $brown-grey;
    }
  }

  & > tbody > tr > td,
  & > thead > tr > th {
    vertical-align: middle;
    padding-left: 15px;
    height: 55px;

    &:first-child {
      text-align: left;
    }

    i {
      width: 20px;
    }
  }

  & > thead > tr > th {
    border-bottom: 1px solid $brown-grey;
  }

  & > tbody > tr > td {
    @include BodyCopy;

    border-top: none;
    border-bottom: 1px solid whitesmoke;
  }
}
