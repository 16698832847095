@import 'palette';

.badge {
  padding: 3px 5px;

  &-info {
    background-color: $very-light-pink;
    color: black;
  }

  &-warning,
  &-danger {
    background-color: $bright-orange;
    color: white;
  }

  &-success {
    background-color: $lightish-green;
    color: white;
  }

  &-watched {
    color: white;
    background-color: $tealish-three;
  }

  &-new {
    color: white;
    background-color: $rosy-pink;
    width: 46px;
    height: 20px;
    font-size: 14px;
  }
}
