@config "../../../tailwind.config.js";
@tailwind base;
@tailwind components;
@tailwind utilities;

$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/bootstrap';

@import '~ladda/css/ladda';
@import '~@fortawesome/fontawesome-pro/css/all.css';
@import '~compass-mixins/lib/compass/typography/links/link-colors';
@import '~compass-mixins/lib/compass/css3/border-radius';
@import '~compass-mixins/lib/compass/css3/images';
@import 'mixins/compass-helpers';
@import 'mixins/bootstrap-switch';

$flagsImagePath: '~intl-tel-input/build/img/';
@import '~intl-tel-input/src/css/intlTelInput';

html {
  font-size: 16px;
}

.iti-flag {
  background-image: image-url('flags.png');
}

.intl-tel-input {
  width: 100%;
}

.terms-overlay {
  position: fixed;
  background: rgba(black, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 15;

  .terms-accept-inner {
    max-width: 500px;
    margin: 10% auto;
    position: relative;
    width: auto;
    pointer-events: none;

    form {
      position: relative;

      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 10px;
      padding: 25px;
      outline: 0;
    }
  }
}

.content-container {
  font-family: 'Comic Sans MS', sans-serif;

  .basically {
    border: 1px solid #ddd;
    background: #fafafa;
    padding: 10px 20px;
    @include border-radius(4px);
    //margin-bottom: 20px;

    p {
      margin-bottom: 0;
    }
  }

  h3 {
    font-size: 19px;
    font-weight: bold;
  }

  table {
    tbody {
      tr {
        td {
          border: 1px solid #ddd;
          padding: 5px;
        }
      }
    }
  }
}

.Select {
  &.is-focused,
  &.is-open {
    > .Select-control {
      background: #f5f5f5;
      border-color: #66afe9;
      outline: 0;
      box-shadow: none;
    }
  }

  .Select-menu-outer {
    margin-top: 0;
  }

  .Select-control {
    height: auto;
    min-height: 45px;
    background: #f5f5f5;
    border: 2px solid transparent;
    color: #444;
    font-weight: bold;
    font-size: 18px;
    @include border-radius(4px);
    @include box-shadow(none);
    box-sizing: inherit;
    display: flex;
    overflow: initial;
    flex-direction: row;

    .Select-multi-value-wrapper {
      flex-grow: 1;
      padding: 0 12px;
    }

    .Select-arrow-zone,
    .Select-clear-zone {
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .Select-placeholder {
      color: #bbb;
    }

    .Select-placeholder,
    .Select-value {
      padding: 0 12px !important;
      line-height: 45px !important;
    }

    .Select-input {
      padding-left: 0;
      line-height: 45px;
    }
  }

  &.Select--multi {
    .Select-input {
      margin-left: 0;
    }
    .Select-control {
      .Select-value {
        padding: 0 !important;
        line-height: 1.6em !important;
      }
    }
  }
}

@import 'theme/colours';
@import 'partials/fonts';
@import 'partials/base';

@import 'theme/colours';
@import 'theme/buttons';
@import 'partials/select2/autocomplete';
@import 'theme/forms';
@import 'theme/navigation';
@import 'theme/nprogress';
@import 'theme/pagination';
@import 'theme/popover';
@import 'theme/breathing';
@import 'theme/misc';
@import 'theme/badges';
@import 'theme/links';
@import 'theme/lists';
@import 'theme/tooltips';
@import 'theme/tasks';
@import 'theme/tables';
@import 'theme/layout';
@import 'theme/cards';
@import 'theme/bullet-tab';
@import 'theme/alerts';

@import 'partials/navigation';
