.react-tel-input {
  input[type='tel'],
  input[type='text'] {
    background: #faf7f0 !important;
    border: none !important;
    color: #666 !important;
    font-weight: bold !important;
    height: 45px !important;
    font-size: 18px !important;
    //padding: 8px 12px!important;

    @include border-radius(4px);
  }

  .selected-flag {
    z-index: 13;
    position: relative;
    width: 38px;
    height: 44px !important;
    padding: 0 0 0 8px;
    border-radius: 3px 0 0 3px;
  }

  .country-list {
    width: 300px !important;
    top: 44px !important;
  }
}

.react-tel-input .country-list .country {
  padding: 5px 10px;
  text-align: left;
}
