$darken-amount: 5%;

@mixin ButtonStyle($font-color, $bg, $bg-hover: null) {
  color: $font-color;
  background-color: $bg;

  $bg-hover: darken($bg, $darken-amount) !default;

  &:hover,
  &:focus {
    background-color: $bg-hover;
  }
}
