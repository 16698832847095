.select2-selection--single {
  box-sizing: border-box;

  cursor: pointer;
  display: block;

  height: 28px;

  user-select: none;
  -webkit-user-select: none;

  .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .select2-selection__clear {
    position: relative;
  }
}

&[dir='rtl'] {
  .select2-selection--single {
    .select2-selection__rendered {
      padding-right: 8px;
      padding-left: 20px;
    }
  }
}

.select2-selection--single {
  background: #faf7f0;
  border: none;
  color: #666;
  font-weight: bold;
  height: 45px;
  font-size: 18px;
  padding: 8px 12px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  outline: 0;

  &:focus {
  }

  .select2-selection__rendered {
    color: #444;
    line-height: 28px;
  }

  .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: bold;
    margin-right: 10px;
  }

  .select2-selection__placeholder {
    color: #999;
  }

  .select2-selection__arrow {
    //background-color: #ddd;

    border: none;
    //border-left: 1px solid $border-color;
    //border-top-right-radius: $border-radius;
    //border-bottom-right-radius: $border-radius;

    height: 26px;

    position: absolute;

    top: 1px;
    right: 1px;

    width: 20px;

    b {
      border-color: #888 transparent transparent transparent;
      border-style: solid;
      border-width: 5px 4px 0 4px;

      height: 0;
      left: 50%;

      margin-left: -4px;
      margin-top: -2px;

      position: absolute;

      top: 50%;
      width: 0;
    }
  }
}

&[dir='rtl'] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      border: none;
      border-right: 1px solid $border-color;

      border-radius: 0;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      left: 1px;
      right: auto;
    }
  }
}

&.select2-container--open {
  .select2-selection--single {
    .select2-selection__arrow {
      background: transparent;

      border: none;

      b {
        border-color: transparent transparent #888 transparent;
        border-width: 0 4px 5px 4px;
      }
    }
  }

  &.select2-container--above {
    .select2-selection--single {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &.select2-container--below {
    .select2-selection--single {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
