@import 'text';

.navbar-default {
  height: 58px;
  min-height: 58px;
  @include border-radius(0);

  background: none;
  border: none;

  .navbar-brand {
    height: 58px;
    padding: 15px;
    line-height: 30px;
  }

  .nav.navbar-nav {
    &.navbar-right {
      > li {
        > a {
          min-width: 58px;
          height: 58px;
          padding: 0;
          display: inline-block;
          text-align: center;
          line-height: 58px;
        }
      }
    }

    .notifications-count {
      height: 25px;
      width: 25px;
      display: inline-block;
      @include border-radius(25px);
      line-height: 25px;
      background: $red;
      color: $yellow-light;
      font-size: 12px;
    }
  }
}

.navigation {
  &-top {
    margin-left: 15px;
    display: flex;
    align-items: center;

    h1 {
      @include Heading;

      margin: 0 0 0 10px;
    }

    .navigation-alert {
      position: relative;
      min-height: 26px;
      min-width: 300px;
      border-radius: 5px;
      line-height: 26px;
      padding: 0 15px;
      padding-right: 30px;
      color: $darkish-pink;
      background-color: #ffffff;
      border: solid 1px $darkish-pink;
      margin: auto;
      span {
        &.text {
          font-family: Lato;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 0.08px;
        }
        a {
          text-decoration: underline;
          font-weight: bold;
          color: $darkish-pink;
        }
      }
      .close {
        position: absolute;
        top: 1px;
        right: 10px;
        opacity: 1;
        color: $darkish-pink;
      }
      &.danger {
        color: #ffffff;
        background-color: $darkish-pink;
        span {
          a {
            color: #ffffff;
          }
        }
        .close {
          opacity: 0.59;
          color: #ffffff;
        }
      }
    }
  }
}
