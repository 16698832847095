.tab-bullet {
  &.vertical-tab {
    position: absolute;
    left: 1vw;
    top: 50%;
    transform: translateY(-150%);
    display: flex;
    flex-direction: column;
    padding-right: 0.5vw;
  }

  button.tab-links {
    width: 14px;
    border-radius: 50%;
    border: none;
    height: 14px;
    transform: rotate(90deg);
    background-color: #e5e3e6;
    margin: 5px;
    cursor: unset;
    &.active {
      background-color: $tealish-two;
    }
  }

  @media (max-width: 768px) {
    display: none !important;
  }
}
