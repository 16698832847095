.tooltip {
  z-index: 9999;
}

#company-logo.tooltip {
  .tooltip-inner {
    max-width: 210px;
  }
}

#upload-library-tooltip {
  z-index: 9999 !important;

  & > div {
    z-index: 9999 !important;
    max-width: 120px !important;

    .__floater__body > div {
      text-align: center !important;
    }
  }
}
