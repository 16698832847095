@import '~compass-mixins/lib/compass/css3/transform';
@import '~compass-mixins/lib/animation/core';

body.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.sweet-overlay {
  background-color: black;
  /* IE8 */
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=40)';
  /* IE8 */
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  z-index: 10000;
}

.sweet-alert {
  width: 478px;
  padding: 17px;
  position: fixed;
  left: 50%;
  top: 50%;
  border-radius: 5px;
  background-color: white;
  text-align: center;
  margin-left: -256px;
  margin-top: -200px;
  overflow: hidden;
  display: none;
  z-index: 99999;

  h2 {
    color: #575757;
    font-size: 30px;
    text-align: center;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 25px 0;
    padding: 0;
    line-height: 40px;
    display: block;
  }

  p {
    color: #797979;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    position: relative;
    float: none;
  }

  fieldset {
    border: none;
    position: relative;
  }

  .sa-error-container {
    background-color: #f1f1f1;
    margin-left: -17px;
    margin-right: -17px;
    overflow: hidden;
    padding: 0 10px;
    max-height: 0;

    @include transition-property(padding 0.15s, max-height 0.15s);

    &.show {
      padding: 10px 0;
      max-height: 100px;
      @include transition-property(padding 0.2s, max-height 0.2s);
    }

    .icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      @include border-radius(50%);
      background-color: #ea7d7d;
      color: white;
      line-height: 24px;
      text-align: center;
      margin-right: 3px;
    }

    p {
      display: inline-block;
    }
  }

  .sa-input-error {
    position: absolute;
    top: 29px;
    right: 26px;
    width: 20px;
    height: 20px;
    opacity: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
  }
}
@media all and (max-width: 540px) {
  .sweet-alert {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 15px;
    right: 15px;
  }
}

.sweet-alert .sa-input-error::before,
.sweet-alert .sa-input-error::after {
  content: '';
  width: 20px;
  height: 6px;
  background-color: #f06e57;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  margin-top: -4px;
  left: 50%;
  margin-left: -9px;
}
.sweet-alert .sa-input-error::before {
  @include transform(rotate(-45deg));
}
.sweet-alert .sa-input-error::after {
  @include transform(rotate(45deg));
}
.sweet-alert .sa-input-error.show {
  opacity: 1;
  @include transform(scale(1));
}
.sweet-alert fieldset input {
  height: 45px;
  width: 100%;
  padding: 8px 12px;
  outline: none;
  border: none;
  box-sizing: border-box;

  margin-top: 10px;
  margin-bottom: 17px;
  display: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;

  background: #faf7f0;
  border: 1px solid $brown-light;
  color: #666;
  font-weight: bold;
  font-size: 18px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.sweet-alert {
  input {
    @include input-placeholder {
      color: #bdbdbd;
    }
    &:focus {
      outline: none;
      @include input-placeholder {
        transition: opacity 0.3s 0.03s ease;
        opacity: 0.5;
      }
    }
  }

  &.show-input {
    input {
      display: block;
    }
  }

  .sa-confirm-button-container {
    display: inline-block;
    position: relative;
  }

  .la-ball-fall {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -27px;
    margin-top: 4px;
    opacity: 0;
    visibility: hidden;
  }

  button {
    padding: 10px 32px;
    margin: 26px 5px 0 5px;
    cursor: pointer;
    position: relative;
    border: none;
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) all !important;
    font-size: 16px;
    font-weight: bold;
    color: white;
    vertical-align: top;
    box-shadow: inset 0 -3px rgba(0, 0, 0, 0.3);
    background: $blue;
    @include border-radius(5px);
    &:focus,
    &:active {
      outline: none;
    }

    &.expanded,
    &.expanded:hover {
      border-color: #666;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 2px rgba(128, 179, 235, 0.5),
        inset 0 0 0 1px rgba(0, 0, 0, 0.05);
    }

    &:active {
      background-color: #5dc2f1;
    }
    &.cancel {
      background-color: #c1c1c1;
      &:hover {
        background-color: #b9b9b9;
      }
      &:active {
        background-color: #a8a8a8;
      }
      &:focus {
        @include box-shadow(
          rgba(197, 205, 211, 0.8) 0px 0px 2px,
          rgba(0, 0, 0, 0.0470588) 0px 0px 0px 1px inset!important
        );
      }
    }
  }

  .sa-icon {
    width: 80px;
    height: 80px;
    padding: 0;
    margin: 20px auto;
    @include border-radius(40px);
    border: 4px solid gray;
    position: relative;
    box-sizing: content-box;
  }
}

.sweet-alert button[disabled] {
  opacity: 0.6;
  cursor: default;
}
.sweet-alert button.confirm[disabled] {
  color: transparent;
}
.sweet-alert button.confirm[disabled] ~ .la-ball-fall {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}
.sweet-alert button::-moz-focus-inner {
  border: 0;
}
.sweet-alert[data-has-cancel-button='false'] button {
  box-shadow: none !important;
}
.sweet-alert[data-has-confirm-button='false'][data-has-cancel-button='false'] {
  padding-bottom: 40px;
}

.sweet-alert .sa-icon.sa-error {
  border-color: #f27474;
}
.sweet-alert .sa-icon.sa-error .sa-x-mark {
  position: relative;
  display: block;
}
.sweet-alert .sa-icon.sa-error .sa-line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #f27474;
  display: block;
  top: 37px;
  border-radius: 2px;
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 17px;
}
.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  right: 16px;
}
.sweet-alert .sa-icon.sa-warning {
  border-color: #f8bb86;
}
.sweet-alert .sa-icon.sa-warning .sa-body {
  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: #f8bb86;
}
.sweet-alert .sa-icon.sa-warning .sa-dot {
  position: absolute;
  width: 7px;
  height: 7px;
  @include border-radius(50%);
  margin-left: -3px;
  left: 50%;
  bottom: 10px;
  background-color: #f8bb86;
}
.sweet-alert .sa-icon.sa-info {
  border-color: #c9dae1;
}
.sweet-alert .sa-icon.sa-info::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 29px;
  left: 50%;
  bottom: 17px;
  @include border-radius(2px);
  margin-left: -2px;
  background-color: #c9dae1;
}
.sweet-alert .sa-icon.sa-info::after {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  @include border-radius(50%);
  margin-left: -3px;
  top: 19px;
  background-color: #c9dae1;
}
.sweet-alert .sa-icon.sa-success {
  border-color: #a5dc86;
}
.sweet-alert .sa-icon.sa-success::before,
.sweet-alert .sa-icon.sa-success::after {
  content: '';
  @include border-radius(40px);
  position: absolute;
  width: 60px;
  height: 120px;
  background: white;
  @include transform(rotate(45deg));
}
.sweet-alert .sa-icon.sa-success::before {
  @include border-radius(120px 0 0 120px);
  top: -7px;
  left: -33px;
  @include transform(rotate(-45deg));
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
}
.sweet-alert .sa-icon.sa-success::after {
  @include border-radius(0 120px 120px 0);
  top: -11px;
  left: 30px;
  @include transform(rotate(-45deg));
  -webkit-transform-origin: 0px 60px;
  transform-origin: 0px 60px;
}
.sweet-alert .sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(165, 220, 134, 0.2);
  @include border-radius(40px);
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}
.sweet-alert .sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: white;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  @include transform(rotate(-45deg));
}
.sweet-alert .sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #a5dc86;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.sweet-alert .sa-icon.sa-custom {
  background-size: contain;
  border-radius: 0;
  border: none;
  background-position: center center;
  background-repeat: no-repeat;
}

@include keyframes(showSweetAlert) {
  0% {
    @include transform(scale(0.7));
  }
  45% {
    @include transform(scale(1.05));
  }
  80% {
    @include transform(scale(0.95));
  }
  100% {
    @include transform(scale(1));
  }
}

@include keyframes(hideSweetAlert) {
  0% {
    @include transform(scale(1));
  }
  100% {
    @include transform(scale(0.5));
  }
}

@include keyframes(slideFromTop) {
  0% {
    top: 0%;
  }
  100% {
    top: 50%;
  }
}

@include keyframes(slideToTop) {
  0% {
    top: 50%;
  }
  100% {
    top: 0%;
  }
}

@include keyframes(slideFromBottom) {
  0% {
    top: 70%;
  }
  100% {
    top: 50%;
  }
}

@include keyframes(slideToBottom) {
  0% {
    top: 50%;
  }
  100% {
    top: 70%;
  }
}

.showSweetAlert[data-animation='pop'] {
  @include animation(showSweetAlert 0.3s);
}

.showSweetAlert[data-animation='none'] {
  @include animation(none);
}

.showSweetAlert[data-animation='slide-from-top'] {
  @include animation(slideFromTop 0.3s);
}

.showSweetAlert[data-animation='slide-from-bottom'] {
  @include animation(slideFromBottom 0.3s);
}

.hideSweetAlert[data-animation='pop'] {
  @include animation(hideSweetAlert 0.2s);
}

.hideSweetAlert[data-animation='none'] {
  @include animation(none);
}

.hideSweetAlert[data-animation='slide-from-top'] {
  @include animation(slideToTop 0.4s);
}

.hideSweetAlert[data-animation='slide-from-bottom'] {
  @include animation(slideToBottom 0.3s);
}

@include keyframes(animateSuccessTip) {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@include keyframes(animateSuccessLong) {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@include keyframes(rotatePlaceholder) {
  0% {
    @include transform(rotate(-45deg));
  }
  5% {
    @include transform(rotate(-45deg));
  }
  12% {
    @include transform(rotate(-405deg));
  }
  100% {
    @include transform(rotate(-405deg));
  }
}

.animateSuccessTip {
  @include animation(animateSuccessTip 0.75s);
}

.animateSuccessLong {
  @include animation(animateSuccessLong 0.75s);
}

.sa-icon.sa-success.animate::after {
  @include animation(rotatePlaceholder 4.25s ease-in);
}

@include keyframes(animateErrorIcon) {
  0% {
    @include transform(rotateX(100deg));
    opacity: 0;
  }
  100% {
    @include transform(rotateX(0deg));
    opacity: 1;
  }
}

.animateErrorIcon {
  @include animation(animateErrorIcon 0.5s);
}

@include keyframes(animateXMark) {
  0% {
    @include transform(scale(0.4));
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    @include transform(scale(0.4));
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    @include transform(scale(1.15));
    margin-top: -6px;
  }
  100% {
    @include transform(scale(1));
    margin-top: 0;
    opacity: 1;
  }
}

.animateXMark {
  @include animation(animateXMark 0.5s);
}

@include keyframes(pulseWarning) {
  0% {
    border-color: #f8d486;
  }
  100% {
    border-color: #f8bb86;
  }
}

.pulseWarning {
  @include animation(pulseWarning 0.75s infinite alternate);
}

@include keyframes(pulseWarningIns) {
  0% {
    background-color: #f8d486;
  }
  100% {
    background-color: #f8bb86;
  }
}

.pulseWarningIns {
  @include animation(pulseWarningIns 0.75s infinite alternate);
}

@include keyframes(rotate-loading) {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Internet Explorer 9 has some special quirks that are fixed here */
/* The icons are not animated. */
/* This file is automatically merged into sweet-alert.min.js through Gulp */
/* Error icon */
.sweet-alert .sa-icon.sa-error .sa-line.sa-left {
  -ms-transform: rotate(45deg) \9;
}

.sweet-alert .sa-icon.sa-error .sa-line.sa-right {
  -ms-transform: rotate(-45deg) \9;
}

/* Success icon */
.sweet-alert .sa-icon.sa-success {
  border-color: transparent\9;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9;
}

.sweet-alert .sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9;
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-fall,
.la-ball-fall > div {
  position: relative;
  @include box-sizing(border-box);
}

.la-ball-fall {
  display: block;
  font-size: 0;
  color: #fff;
}

.la-ball-fall.la-dark {
  color: #333;
}

.la-ball-fall > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-fall {
  width: 54px;
  height: 18px;
}

.la-ball-fall > div {
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 100%;
  opacity: 0;
  @include animation(ball-fall 1s ease-in-out infinite);
}

.la-ball-fall > div:nth-child(1) {
  @include animation-delay(-200ms);
}

.la-ball-fall > div:nth-child(2) {
  @include animation-delay(-100ms);
}

.la-ball-fall > div:nth-child(3) {
  @include animation-delay(0ms);
}

.la-ball-fall.la-sm {
  width: 26px;
  height: 8px;
}

.la-ball-fall.la-sm > div {
  width: 4px;
  height: 4px;
  margin: 2px;
}

.la-ball-fall.la-2x {
  width: 108px;
  height: 36px;
}

.la-ball-fall.la-2x > div {
  width: 20px;
  height: 20px;
  margin: 8px;
}

.la-ball-fall.la-3x {
  width: 162px;
  height: 54px;
}

.la-ball-fall.la-3x > div {
  width: 30px;
  height: 30px;
  margin: 12px;
}

@include keyframes(ball-fall) {
  0% {
    opacity: 0;
    @include translateY(-145%);
  }
  10% {
    opacity: 0.5;
  }
  20% {
    opacity: 1;
    @include translateY(0);
  }
  80% {
    opacity: 1;
    @include translateY(0);
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    @include translateY(145%);
  }
}
