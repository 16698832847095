@import '~compass-mixins/lib/compass/typography/links/link-colors';
@import 'icons';

$font-highlight: 'Quicksand', sans-serif;

@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  zoom: 1; // For IE 6/7 (trigger hasLayout)
}

.line-break {
  margin: 30px 0;
  position: relative;
  width: 100%;
  text-align: center;
  line-height: 1em;

  .break-text {
    padding: 0 4px;
    font-size: 16px;
    display: inline-block;
    position: relative;
    background: white;
    color: #444;
    font-weight: bold;
  }

  .break-line {
    height: 1px;
    width: 100%;
    background: #999;
    position: absolute;
    top: 50%;
  }
}

html {
  width: 100%;
  margin: 0;
  padding: 0;

  * {
    -webkit-font-smoothing: antialiased !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    box-sizing: border-box;
  }
}

body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  line-height: 1.428571429;
  color: #333333;
  font-family: 'Lato', Arial, sans-serif;
}

a {
  text-decoration: none;
  outline: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

h3 {
  font-family: 'Circular Std', Arial, san-serif;
  font-weight: bold;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: $body-bg;
  border: 1px solid #e2e0d9;
  border-radius: 4px;
  @include box-shadow(none);
}

.panel {
  padding: 10px 15px;

  &.panel-medium {
    .panel-body {
      height: 250px;
      overflow: auto;
    }
  }

  .panel-header {
    font-size: 1.2em;
    line-height: 2em;
    font-weight: bold;
  }
}

.panel-content {
  background: white;

  .panel-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 15px 0;
    flex-grow: 0;
    align-items: center;
  }

  .panel-body {
    padding: 15px 20px;
  }

  .panel-table {
  }
}

.panel-minimal {
  max-width: 450px;
  margin: 0 auto;
}

.panel-item {
  @include border-radius(5px);
  border: 1px solid lighten($brown-light, 15%);
  padding: 5px 10px;
}

.alert {
  padding: 8px 12px;
  background: white;
  @include border-radius(6px);
  font-size: 1.1em;
  overflow: hidden;

  border-left: 5px solid $blue;
  color: $blue;
  font-weight: bold;
  font-family: $font-highlight;

  &.alert-info {
  }

  &.alert-danger {
    border-color: $red;
    color: $red;
  }

  p {
    &.alert-description {
      font-weight: normal;
    }
  }
}

.brand-container {
  display: inline-block;
  height: 58px;
  padding: 15px;
  line-height: 30px;

  .brand-bear {
    display: inline-block;
    height: 36px;
    margin-right: 4px;
  }

  .brand-text {
    height: 20px;
    display: inline-block;
  }
}

.popover {
  max-width: none;
}

.container-minimal {
  width: 450px;
  margin: 5% auto 20px;
}

#{headings(all)} {
  color: $brown-dark;
}

.help-block {
  color: $red;
  margin-bottom: unset;
}

.no-bottom-margin {
  margin-bottom: 0;
}
